.card {
  /* Display a card as a flex box containing the cards name, img, and links */
  display: flex;
  flex-direction: column;
  
  /* Set The shape of the card */
  margin: 0px 5px;
  box-sizing: border-box;
  border: '2px solid #aa0000';
  align-items: center;
  justify-content: initial;
  border-radius: 2px;
  padding: 10px 0px;

  /* Add bottom border */
  border-bottom: 3px dotted rgb(200, 201, 202);
}

.card button {
  background-color: #222222;
  color: #d3d3d3;
  font-size: 18px;
  font-style: bold;
  text-align: center;
  line-height: 40px;
  
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px 0px 0px 10px;
  border-right: 1px solid rgb(200, 201, 202);
  transition: 0.3s;
}

.card button:hover {
  background-color: #ffb9b9;
  color: #222222;
}

.card button.link {
  width: 40px;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  justify-content: center;
}

.card button.link img {
  width: 25px;
  height: 25px;
  filter: invert(90%);
  margin: auto;
}

.card button.link img:hover {
  filter: invert(10%);
}

.card .nameButton {
  width: auto;
  height: 38px;
  outline: none;
  border: none;
  background-color: transparent;
  color: #222222;
  border-radius: 1px;
  font-size: 22px;
  padding: 2px;
  margin: 0px;
  transition: .3s;
}

.card .nameButton:hover {
  color: #ffb9b9;
  background-color: transparent;
}


.card .ButtLayout {
  display: flex;
  width: 300px;
  padding: 5px 15px;
}

.card .cardBox {
  display: grid;
  width: 320px;
  position: relative;
}

.card img {
  width: 320px;
  grid-row: 1;
  grid-column: 1;
}

.card .cardBox img.rotate.loading {
  visibility: visible;
}

.card .cardBox img.rotate {
  visibility: hidden;
  grid-row: 1;
  grid-column: 1;
  margin-top: 27%;
  margin-left: 30%;
  position: relative;
  z-index: 1;
  width: 130px;
  animation: rotation 1.6s infinite linear;
}

button.pack {
  border-radius: 10px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}