.box {
  /* Hold the box which contains and manages the header and body */
  display: block;
  direction: column;

  box-sizing: border-box;
  
  /* Set The shape of the box */
  width: 100%;
  align-items: center;
  justify-content: initial;
  padding: 10px 0;
}

/* The pressable header */
.box button {
  display: flex;
  box-sizing: border-box;

  color: #222222;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  
  border: none;
  width: 100%;
  border-bottom: 1px solid rgb(200, 201, 202);

  transition: .3s;
}

.box button:hover {
  background-color: #ffb9b9;
}

.box button img {
  transition: 0.3s;

  width: 25px;
  height: 25px;
  filter: invert(10%);
  padding: 0px 10px;
  margin: auto;
}

/* Expanded hover image */
.box button:hover img.expanded {
  transform: rotate(-90deg);
}

/* NOT-Expanded hover image */
.box button:hover img {
  transform: rotate(90deg);
}


.box button p {
  color: #222222;
  text-align: left;
  font-size: 22px;
  padding: 2px;
  margin: 0px;
  width: 100%;
}


/* Body BOX */
.box .body {
  width: 100%;
  transition: .3s;
  padding: 0px 10px;

  /* Set height to min and remove overfolow */
  overflow: hidden;
  box-sizing: border-box;
}

.box .body p {
  margin: 5px 5px 0px 5px;  
}
