body {
  margin: 0;
  font-family: Gill Sans, Gill Sans MT,Calibri, sans-serif;
  min-height: 100%;
  background-color: #ebebeb;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  background-color: #222222;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

