.container {
    padding: 10px 0px 0px 0px;
    width: 100%;
    max-width:1200px;
    margin: 0px auto;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
  }

  .container p {
    font-size: 18px;
  }

.widthHandler {
  width: 100%;
  margin: 0px 15px;
}

img.qwrl {
  width: 200px;
  padding-top: 20px;
  margin: 0px 20px;
}