/*
/// Section for PAGE COMPOSITION
*/

.content {
  order: 2;
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
  order: 3;
}

/*
/// Section for HEADER
*/

/* Style the header with a grey background and some padding */
.header {
  /* width: 100%;                   /* Header width */
  overflow: hidden;
  background-color: #222222;
  padding: 0px 5px;
}

/* Style the header links */
.header a {
  height: 30px;
  float: left;
  
  color: #d3d3d3;
  text-align: center;
  font-weight: bold;
  text-decoration: none;

  padding: 15px 12px;
  font-size: 18px;
  min-width: 55px;
  border-radius: 5px;

  transition: 0.3s;
}

/* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
.header a.logo {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  height: 50px;
  padding: 5px 2px;
  gap: 7px;
  display: flex;
  margin: auto;
}

/* Change the background color on mouse-over */
.header a:hover {
  background-color: #ffb9b9;
  color: #222222;
}

/* Float the link section to the right */
.header-right {
  float: right;
}

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 600px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  .header-right {
    float: none;
  }
}

/*
/// Section for FOOTER
*/

/* Style the header with a grey background and some padding */
.footer {
  overflow: hidden;
  background-color: #222222;
  
  padding: 0px 5px;
  min-height: 130px;
  height: auto;

  display: flex;
}

/* Style the header links */
.footer a {
  float: left;
  color: #d3d3d3;

  font-family: Gill Sans,Gill Sans MT,Calibri,sans-serif;
  padding: 5px 5px;
  margin: 10px 15px;
  text-decoration: none;
  font-size: 14px;
  min-width: 20px;
  
  transition: 0.3s;
}

/* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
.footer a.logo {
  justify-content: center;
  padding: 5px 2px;
  height: 50px;
}

/* Change the background color on mouse-over */
.footer a:hover {
  color: #ffb9b9;
}

/* Style the header links */
.footer a.symbol {
  padding: 5px 5px;
  margin: 10px 15px;
  border-radius: 5px;
}

/* Change the background color on mouse-over */
.footer a.symbol:hover {
  background-color: #ffb9b9;
}

/* Float the link section to the right */
.footer-box {
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Float the link section to the right */
.link-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0px;
}

/* Float the sets in the middle */
.footer-center {
  justify-content: center;
  display: grid;
  padding: 5px 0px;
  margin: 0px;
  flex-basis: 100%;
}

.footer-center text {
  color: #d3d3d3;
  font-family: Gill Sans,Gill Sans MT,Calibri,sans-serif;
  text-align: center;
  padding: 0px;
  margin: 0px 10px;
}

.footer-center set {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px 0px;
  
}

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 600px) {
  .footer {
    display: block;
  }

  .footer-box {
    width: 100%;
  }
}


/* Home Page Content */
.homeBound {
  margin: 15px auto;
  padding: 0px 15px;
  max-width: 1200px;
  flex-direction: row;
  display: flex;
  align-items: top;
  justify-content: center;
}

.homeBound .slideShowHolder {
  display: initial;
  height: 100%;
}

.homeBound .homeTextBox {
  min-width: 260px;
  margin: 10px 10px 10px 10px
}

.homeBound .homeTextBox p {
  font-size: 18px;
}



/* If the screen is small, change the size of the screen to account for  */
@media screen and (max-width: 600px) {
  .homeBound {
    flex-direction: column;
  }
}
